import axios from 'axios'

const Liquidacion = {
    listaPendientes(){
        return axios.get(`comisiones/liquidacion/pendientes`)
    },
    generalPendientes(){
        return axios.get(`comisiones/liquidacion/pendientes-general`)
    },
    updateEstadoComision(form){
        return axios.put(`comisiones/liquidacion/pendientes`,form)
    },
    listaPorPagar(){
        return axios.get(`comisiones/liquidacion/por-pagar`)
    },
    generalPorPagar(){
        return axios.get(`comisiones/liquidacion/por-pagar-general`)
    },
    detalleComisiones(id_tienda){
        return axios.get(`comisiones/liquidacion/lechero/${id_tienda}/detalle`)
    },
    liquidarComisiones(id_tienda, form){
        return axios.post(`comisiones/liquidacion/lechero/${id_tienda}/liquidar`,form)
    },
    listaPagadas(){
        return axios.get(`comisiones/liquidacion/pagadas`)
    },
    detalleComisionPagada(id){
        return axios.get(`comisiones/liquidacion/${id}/pagada`)
    },
    getLecheros(id_cedis){
        return axios.get(`comisiones/liquidacion/filtro/${id_cedis}/lecheros`)
    },
    getResponsables(){
        return axios.get(`comisiones/liquidacion/filtro/responsables`)
    },
    getExportarComisionesPagadas(params){
        return axios.get(`comisiones/liquidacion/exportar-pagadas`, {params})
    },
    getPedidos(id){
        return axios.get(`comisiones/liquidacion/${id}/buscar-pedido`)
    }
}

export default Liquidacion
